import React from "react";
import bagBuilder from "../../../assets/mainSection/bagBuilder.png";
import discSearch from "../../../assets/mainSection/discSearch.png";
import profile from "../../../assets/mainSection/profile.png";
import shop from "../../../assets/mainSection/shop.png";

import "./Styles.css";

const images = [
  {
    image: shop,
    title: "Shop",
  },
  {
    image: discSearch,
    title: "Disc Search",
  },
  {
    image: bagBuilder,
    title: "Bag Builder",
  },
  {
    image: profile,
    title: "Profile",
  },
];

const MainSection = () => {
  const mapImages = () => {
    return images.map((val, i) => (
      <div className="mainSectionImageContainer">
        <p className="mainSectionImageTitle">{val.title}</p>
        <img
          src={val.image}
          className="mainSectionImage"
          id={`mainSectionImage${i + 1}`}
        />
      </div>
    ));
  };

  return (
    <div className="mainSectionwrapper">
      <div className="mainSectionContainer">{mapImages()}</div>
    </div>
  );
};

export default MainSection;
