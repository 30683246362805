import React from "react";
import getLogo from "../Logo/getLogo";
import Ratings from "../Ratings/Ratings";

import "./Styles.css";

const Disc = ({ data, handleSetLearnMoreData }) => {
  return (
    <div className="demo-card-wide mdl-card mdl-shadow--8dp discWrapper">
      <div className="logoContainer">
        <img className="logo" src={getLogo(data.data.brand)} />
      </div>
      <div className="discHeaderContainer">
        <div>
          <p className="discHeaderTitle">{`${data.data.title.slice(0, 15)}${
            data.data.title.length > 15 ? ".." : ""
          }`}</p>
        </div>
        <div>
          <p className="discHeaderType">{data.data.type}</p>
        </div>
      </div>
      <div>
        <Ratings ratings={data.data.infinite_ratings} />
      </div>
      <div className="buttonContainer">
        <button className="button" onClick={() => handleSetLearnMoreData(data)}>
          Details
        </button>
      </div>
    </div>
  );
};

export default Disc;
