import React from "react";
import logo from "../../../assets/logo.jpg";
import iosBadge from "../../../assets/iosBadge.png";
import googleBadge from "../../../assets/googleBadge.png";

import "./Styles.css";

const Header = () => {
  return (
    <div className="headerWrapper">
      <div className="headerbuttonWrapper" />
      <img src={logo} className="headerlogo" />
      <div className="headerbuttonWrapper">
        <a
          href="https://apps.apple.com/us/app/squalla-disc-search/id1510168531"
          className="headerButton"
        >
          <img alt="Get it on iOS app store" src={iosBadge} height="40px" />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.squalla.discsearch&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          className="headerButton"
        >
          <img alt="Get it on Google Play" src={googleBadge} height="40px" />
        </a>
      </div>
    </div>
  );
};

export default Header;
