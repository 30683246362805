import React from "react";
import Header from "../Landing/Sections/Header/Header";
import Release from "./Components/Release";
import Footer from "../Landing/Sections/Footer/Footer";

import releases from "./releases.json";

import "./Styles.css";

const Releases = () => {
  return (
    <div className="releasesWrapper">
      <Header />
      <div className="releasesContainer">
        <p className="releasesTitle">App Release Information</p>
        {releases.map((r) => (
          <Release data={r} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Releases;
