import React from "react";
import discs from "../../../assets/shopSection/discs.png";
import pdp from "../../../assets/shopSection/pdp.png";
import sales from "../../../assets/shopSection/sales.png";
import search from "../../../assets/shopSection/search.png";

import "./Styles.css";

const images = [
  {
    image: sales,
    title: "Daily Sales",
  },
  {
    image: search,
    title: "Easy Search",
  },
  {
    image: discs,
    title: "Pictures Of Every Disc",
  },
  {
    image: pdp,
    title: "Easy Shopping",
  },
];

const ShopSection = () => {
  const mapImages = () => {
    return images.map((val, i) => (
      <div className="shopSectionImageContainer">
        <p className="shopSectionImageTitle">{val.title}</p>
        <img
          src={val.image}
          className="shopSectionImage"
          id={`shopSectionImage${i + 1}`}
        />
      </div>
    ));
  };

  return (
    <div className="shopSectionWrapper">
      <div className="shopSectionContainer">{mapImages()}</div>
      <div className="shopSectionAlso">
        <p className="shopSectionSpan">Also Includes:</p>
        <p className="shopSectionText">Saved Carts</p>
      </div>
    </div>
  );
};

export default ShopSection;
