import React from "react";

import "./Styles.css";

const Modal = ({ children, close }) => {
  return (
    <div className="modalWrapper">
      <div className="mdl-shadow--8dp mdl-dialog modalContainer">
        <button className="modalCloseButton" onClick={close}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
