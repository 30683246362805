import React from "react";

import "./Styles.css";

const Ratings = ({ ratings, inbound }) => {
  if (!ratings) {
    return (
      <div className="ratingsWrapper">
        <p style={{ textAlign: "left" }}>Unavailable</p>
      </div>
    );
  }
  const formatted = ratings.split("/");
  const array = formatted.map((arr) => {
    let cont = arr;
    if (arr.includes(".") && arr[arr.length - 1] === "0") {
      cont = arr.substring(0, arr.length - 2);
    }
    return cont;
  });

  return (
    <div className="ratingsWrapper">
      <p className="rating">{inbound ? array[0] + "'" : array[0]}</p>
      <p className="rating">{array[1]}</p>
      <p className="rating">{array[2]}</p>
      <p className="rating4">{array[3]}</p>
    </div>
  );
};

export default Ratings;
