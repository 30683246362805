import React from "react";
import compare from "../../../assets/discSearchSection/compare.png";
import filter from "../../../assets/discSearchSection/filter.png";
import info from "../../../assets/discSearchSection/info.png";
import molds from "../../../assets/discSearchSection/molds.png";

import "./Styles.css";

const images = [
  {
    image: molds,
    title: "1700+ PDGA Approved Discs",
  },
  {
    image: filter,
    title: "Easy Filtering & Search",
  },
  {
    image: info,
    title: "Info On Every Mold",
  },
  {
    image: compare,
    title: "Visual Comparisons",
  },
];

const DiscSearchSection = () => {
  const mapImages = () => {
    return images.map((val, i) => (
      <div className="discSearchSectionImageContainer">
        <p className="discSearchSectionImageTitle">{val.title}</p>
        <img
          src={val.image}
          className="discSearchSectionImage"
          id={`discSearchSectionImage${i + 1}`}
        />
      </div>
    ));
  };

  return (
    <div className="discSearchSectionWrapper">
      <div className="discSearchSectionContainer">{mapImages()}</div>
    </div>
  );
};

export default DiscSearchSection;
