import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import colorArray from "./colors";

am4core.useTheme(am4themes_animated);

const AmChart = ({ chartData }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("chartdiv", am4charts.XYChart);

    let xAxis = x.xAxes.push(new am4charts.ValueAxis());
    xAxis.min = -4;
    xAxis.max = 4;
    xAxis.strictMinMax = true;
    xAxis.renderer.minGridDistance = 25;
    xAxis.renderer.inversed = true;
    xAxis.renderer.labels.template.disabled = true;

    let yAxis = x.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.max = 500;
    yAxis.title.text = "Distance (ft)";
    yAxis.renderer.minGridDistance = 25;

    chartData.forEach((cd, i) => {
      if (cd.chartData) {
        let s = x.series.push(new am4charts.LineSeries());

        s.dataFields.valueX = "x";
        s.dataFields.valueY = "y";
        s.strokeWidth = 2;
        s.stroke = colorArray[i];
        s.data = cd.chartData.fh1;
        s.static = cd.chartData;
        s.name = cd.name ? cd.name : cd.title;
      }
    });

    x.legend = new am4charts.Legend();
    x.legend.scrollable = true;
    x.legend.maxHeight = 100;
    x.legend.maxWidth = 300;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <div
      id="chartdiv"
      style={{
        height: window.screen.height - 92,
        maxHeight: 750,
      }}
    ></div>
  );
};
export default AmChart;
