import "./Landing.css";

import Header from "./Landing/Sections/Header/Header";
import MainSection from "./Landing/Sections/MainSection/MainSection";
import ShopSection from "./Landing/Sections/ShopSection/ShopSection";
import DiscSearchSection from "./Landing/Sections/DiscSearchSection/DiscSearchSection";
import BagBuilderSection from "./Landing/Sections/BagBuilderSection/BagBuilderSection";
import ProfileSection from "./Landing/Sections/ProfileSection/ProfileSection";
import Footer from "./Landing/Sections/Footer/Footer";

function Landing() {
  return (
    <div className="Landing">
      <Header />
      <MainSection />
      <ShopSection />
      <DiscSearchSection />
      <BagBuilderSection />
      <ProfileSection />
      <Footer />
    </div>
  );
}

export default Landing;
