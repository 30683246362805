import React from "react";
import bagChart from "../../../assets/bagBuilderSection/bagChart.png";
import buildCollection from "../../../assets/bagBuilderSection/buildCollection.png";
import flightChart from "../../../assets/bagBuilderSection/flightChart.png";
import personalize from "../../../assets/bagBuilderSection/personalize.png";

import "./Styles.css";

const images = [
  {
    image: buildCollection,
    title: "Build Your Collection",
  },
  {
    image: personalize,
    title: "Personalize Your Discs",
  },
  {
    image: bagChart,
    title: "Grid Charts",
  },
  {
    image: flightChart,
    title: "Flight Charts",
  },
];

const BagBuilderSection = () => {
  const mapImages = () => {
    return images.map((val, i) => (
      <div className="bagBuilderSectionImageContainer">
        <p className="bagBuilderSectionImageTitle">{val.title}</p>
        <img
          src={val.image}
          className="bagBuilderSectionImage"
          id={`bagBuilderSectionImage${i + 1}`}
        />
      </div>
    ));
  };

  return (
    <div className="bagBuilderSectionWrapper">
      <div className="bagBuilderSectionContainer">{mapImages()}</div>
      <div className="bagBuilderSectionAlso">
        <p className="bagBuilderSectionSpan">Also Includes:</p>
        <p className="bagBuilderSectionText">Bag Sharing</p>
      </div>
    </div>
  );
};

export default BagBuilderSection;
