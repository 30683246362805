import React from "react";

import Disc from "../Disc/Disc";
import Modal from "../Modal/Modal";
import LearnMore from "../LearnMore/LearnMore";
import GridChart from "../GridChart/GridChart";
import AmChart from "../../../utils/amCharts";

import "./Styles.css";

const Discs = ({
  discs,
  learnMoreOpen,
  learnMoreData,
  handleSetLearnMoreData,
  gridOpen,
  toggleLearnMore,
  toggleGrid,
  chartOpen,
  toggleChart,
}) => {
  let content = null;

  if (discs.length > 0) {
    content = discs.map((disc, i) => (
      <Disc
        key={`disc-${i}`}
        data={disc}
        handleSetLearnMoreData={handleSetLearnMoreData}
      />
    ));
  }

  return (
    <div className="discsWrapper">
      {learnMoreOpen ? (
        <Modal close={toggleLearnMore}>
          <LearnMore data={learnMoreData} />
        </Modal>
      ) : null}
      {gridOpen ? (
        <Modal close={toggleGrid}>
          <GridChart data={discs} />
        </Modal>
      ) : null}
      {chartOpen ? (
        <Modal close={toggleChart}>
          <div className="amChartContainer" style={{ overflowY: "scroll" }}>
            <AmChart chartData={discs.map((d) => d.data)} />
          </div>
        </Modal>
      ) : null}

      <div className="discsContainer">{content}</div>
    </div>
  );
};

export default Discs;
