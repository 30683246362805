import React, { useState, useEffect } from "react";

const driverColor = "#c0392b";
const fairwayColor = "#8e44ad";
const midColor = "#3498db";
const putterColor = "#7f8c8d";

const topRowData = [
  "Speed",
  "Very Overstable",
  "Overstable",
  "Stable",
  "Understable",
  "Very Understable",
];

const sideRowData = [
  "14",
  "13",
  "12",
  "11",
  "10",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
];

const styles = {
  container: {
    backgroundColor: "white",
    color: "black",
    height: "100%",
    borderRadius: 4,
    overflow: "scroll",
    margin: "auto",
  },
  topRowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  rowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  box: {
    borderLeft: "1px solid lightGrey",
    borderTop: "1px solid lightGrey",
    width: 75,
    height: 50,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topRowTitle: {
    fontSize: 8,
    margin: 0,
    lineHeight: 1.25,
  },
};

const setData = (discs) => {
  const data = {};

  discs.forEach((d) => {
    let speed = d.data.man_ratings.split("/")[0];
    speed = parseFloat(speed);
    const stability = d.data.stability;

    let letter;
    let num;

    switch (stability) {
      case "Very Overstable":
        num = 1;
        break;
      case "Overstable":
        num = 2;
        break;
      case "Stable":
        num = 3;
        break;
      case "Understable":
        num = 4;
        break;
      case "Very Understable":
        num = 5;
        break;
      default:
        break;
    }

    switch (speed) {
      case 1:
        letter = "n";
        break;
      case 2:
        letter = "m";
        break;
      case 3:
        letter = "l";
        break;
      case 4:
        letter = "k";
        break;
      case 5:
        letter = "j";
        break;
      case 6:
        letter = "i";
        break;
      case 7:
        letter = "h";
        break;
      case 8:
        letter = "g";
        break;
      case 9:
        letter = "f";
        break;
      case 10:
        letter = "e";
        break;
      case 11:
        letter = "d";
        break;
      case 12:
        letter = "c";
        break;
      case 13:
        letter = "b";
        break;
      case 14:
        letter = "a";
        break;
      default:
        break;
    }
    data[`${letter}${num}`] = data[`${letter}${num}`]
      ? data[`${letter}${num}`] + 1
      : 1;
  });
  return data;
};

const GridChart = ({ data }) => {
  const [discData, setDiscData] = useState({});

  useEffect(() => {
    setDiscData(setData(data));
  }, []);

  const setLastBoxStyle = (i) => {
    if (i === topRowData.length - 1) {
      return styles.lastBox;
    }
    return {};
  };

  const setBackgroundColor = (setColor, color) => {
    if (setColor) {
      return { backgroundColor: color, fontWeight: 600 };
    }
    return {};
  };

  const renderTopRow = () => {
    return topRowData.map((item, i) => (
      <div style={{ ...styles.box, ...setLastBoxStyle(i) }}>
        <p style={styles.topRowTitle}>{item}</p>
      </div>
    ));
  };

  const renderRows = () => {
    return sideRowData.map((item, i) => {
      let letter;
      let bgColor;
      switch (i) {
        case 13:
          letter = "n";
          bgColor = putterColor;
          break;
        case 12:
          letter = "m";
          bgColor = putterColor;
          break;
        case 11:
          letter = "l";
          bgColor = putterColor;
          break;
        case 10:
          letter = "k";
          bgColor = midColor;
          break;
        case 9:
          letter = "j";
          bgColor = midColor;
          break;
        case 8:
          letter = "i";
          bgColor = fairwayColor;
          break;
        case 7:
          letter = "h";
          bgColor = fairwayColor;
          break;
        case 6:
          letter = "g";
          bgColor = fairwayColor;
          break;
        case 5:
          letter = "f";
          bgColor = fairwayColor;
          break;
        case 4:
          letter = "e";
          bgColor = driverColor;
          break;
        case 3:
          letter = "d";
          bgColor = driverColor;
          break;
        case 2:
          letter = "c";
          bgColor = driverColor;
          break;
        case 1:
          letter = "b";
          bgColor = driverColor;
          break;
        case 0:
          letter = "a";
          bgColor = driverColor;
          break;
        default:
          break;
      }
      return (
        <div style={styles.rowContainer}>
          <div style={{ ...styles.box, ...setLastBoxStyle() }}>
            <p style={styles.topRowTitle}>{item}</p>
          </div>
          <div
            style={{
              ...styles.box,
              ...setLastBoxStyle(),
              ...setBackgroundColor(discData[`${letter}1`], bgColor),
            }}
          >
            {discData[`${letter}1`] ? discData[`${letter}1`] : ""}
          </div>
          <div
            style={{
              ...styles.box,
              ...setLastBoxStyle(),
              ...setBackgroundColor(discData[`${letter}2`], bgColor),
            }}
          >
            {discData[`${letter}2`] ? discData[`${letter}2`] : ""}
          </div>
          <div
            style={{
              ...styles.box,
              ...setLastBoxStyle(),
              ...setBackgroundColor(discData[`${letter}3`], bgColor),
            }}
          >
            {discData[`${letter}3`] ? discData[`${letter}3`] : ""}
          </div>
          <div
            style={{
              ...styles.box,
              ...setLastBoxStyle(),
              ...setBackgroundColor(discData[`${letter}4`], bgColor),
            }}
          >
            {discData[`${letter}4`] ? discData[`${letter}4`] : ""}
          </div>
          <div
            style={{
              ...styles.box,
              ...setLastBoxStyle(),
              ...setBackgroundColor(discData[`${letter}5`], bgColor),
            }}
          >
            {discData[`${letter}5`] ? discData[`${letter}5`] : ""}
          </div>
        </div>
      );
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.topRowContainer}>{renderTopRow()}</div>
      {renderRows()}
    </div>
  );
};

export default GridChart;
