import React from "react";

import aerobie from "./images/aerobie.png";
import agl from "./images/agl.png";
import aquaFlight from "./images/aquaflight.png";
import axiom from "./images/axiom.png";
import ching from "./images/ching.png";
import daredevil from "./images/daredevil.png";
import dga from "./images/dga.png";
import discmania from "./images/discmania.png";
import discraft from "./images/discraft.png";
import dynamic from "./images/dynamic.png";
import element from "./images/element.png";
import fullTurn from "./images/fullturn.png";
import gateway from "./images/gateway.png";
import galaxy from "./images/galaxy.png";
import handCandy from "./images/handCandy.png";
import hyzerBomb from "./images/hyzerbomb.png";
import infinite from "./images/infinite.png";
import innova from "./images/innova.png";
import kastaplast from "./images/kastaplast.png";
import latitude from "./images/latitude.png";
import legacy from "./images/legacy.png";
import lightning from "./images/lightning.png";
import millennium from "./images/millennium.png";
import mvp from "./images/mvp.png";
import niteIze from "./images/niteIze.png";
import ozone from "./images/ozone.png";
import prodigy from "./images/prodigy.png";
import prodiscus from "./images/prodiscus.png";
import reptilian from "./images/reptilian.png";
import rpm from "./images/rpm.png";
import salient from "./images/salient.png";
import streamline from "./images/streamline.png";
import storm from "./images/storm.png";
import tobu from "./images/tobu.png";
import vibram from "./images/vibram.png";
import viking from "./images/viking.png";
import westside from "./images/westside.png";
import yikun from "./images/yikun.png";
import fourthCircle from "./images/4circle.png";
import guru from "./images/guru.png";
import mint from "./images/mint.png";
import skyquest from "./images/skyquest.png";
import kaufinator from "./images/kaufinator.png";
import abc from "./images/abc.png";
import arsenal from "./images/arsenal.png";
import plasticAddicts from "./images/plasticAddicts.png";
import thoughtSpace from "./images/thoughtSpace.png";
import wild from "./images/wild.png";
import xcom from "./images/xcom.png";
import ev7 from "./images/ev7.png";
import cheengz from "./images/cheengz.png";
import dguk from "./images/dguk.png";
import dino from "./images/dino.png";
import disctroyer from "./images/disctroyer.png";
import divergent from "./images/divergent.png";
import elevation from "./images/elevation.png";
import launch from "./images/launch.png";
import lsd from "./images/lsd.png";
import quest from "./images/quest.png";
import sune from "./images/sune.png";
import crosslap from "./images/crosslap.png";
import obsidian from "./images/obsidian.png";
import wingit from "./images/wingIt.png";
import momentum from "./images/momentum.png";
import terminal from "./images/terminal.png";
import birdie from "./images/birdie.png";
import clash from "./images/clash.png";
import alfa from "./images/alfa.png";
import finishline from "./images/finishline.png";
import goliath from "./images/goliath.png";
import loft from "./images/loft.png";
import uplay from "./images/uplay.png";
import blackZombie from "./images/blackzombie.png";
import hooligan from "./images/hooligan.png";
import kestrel from "./images/kestrel.png";
import premier from "./images/premier.png";
import northstar from "./images/northstar.png";
import doomsday from "./images/doomsday.png";
import albatross from "./images/albatross.png";
import deity from "./images/deity.png";
import discKing from "./images/discKing.png";
import discwing from "./images/discwing.png";
import dmiSports from "./images/dmiSports.png";
import essential from "./images/essential.png";
import eurodisc from "./images/eurodisc.png";
import ferrisState from "./images/ferrisState.png";
import lbSport from "./images/lbSport.png";
import paradigm from "./images/paradigm.png";
import parkedLLC from "./images/parkedLLC.png";
import piePan from "./images/piePan.png";
import skyIron from "./images/skyIron.png";
import snap from "./images/snap.png";
import synergy from "./images/synergy.png";
import tokyoDiscs from "./images/tokyoDiscs.png";
import tornado from "./images/tornado.png";
import whamo from "./images/whamo.png";
import flyHigh from "./images/flyHigh.png";
import trashPanda from "./images/trashPanda.png";
import lasAves from "./images/lasAves.png";
import heroDisc from "./images/heroDisc.png";
import newbSimhouse from "./images/newbSimhouse.png";
import remix from "./images/remix.png";
import taito from "./images/taito.png";
import gtDiscs from "./images/gtDiscs.png";

const Logo = (brand) => {
  switch (brand) {
    case "Discmania":
      return discmania;
    case "Discraft":
      return discraft;
    case "Dynamic Discs":
      return dynamic;
    case "Innova Champion Discs":
      return innova;
    case "Kastaplast":
      return kastaplast;
    case "Latitude 64":
      return latitude;
    case "Millennium Golf Discs":
      return millennium;
    case "MVP Disc Sports":
      return mvp;
    case "Prodigy Disc":
      return prodigy;
    case "Vibram Disc Golf":
      return vibram;
    case "Westside Golf Discs":
      return westside;
    case "Aerobie":
      return aerobie;
    case "Axiom Discs":
      return axiom;
    case "CHING Sports":
      return ching;
    case "Daredevil Discs":
      return daredevil;
    case "Disc Golf Association":
      return dga;
    case "Element Discs":
      return element;
    case "Full Turn Discs":
      return fullTurn;
    case "Gateway Disc Sports":
      return gateway;
    case "Hyzer Bomb":
      return hyzerBomb;
    case "Legacy Discs":
      return legacy;
    case "Lightning Discs":
      return lightning;
    case "Nite Ize":
      return niteIze;
    case "Prodiscus":
      return prodiscus;
    case "Reptilian Disc Golf":
      return reptilian;
    case "RPM Discs":
      return rpm;
    case "RPM":
      return rpm;
    case "Salient Discs":
      return salient;
    case "Tobu Discs":
      return tobu;
    case "UB Disc Golf - Hand Candy":
      return handCandy;
    case "Viking Discs (IP-Agency Finland Oy)":
      return viking;
    case "Yikun Discs":
      return yikun;
    case "AquaFlight Discs":
      return aquaFlight;
    case "Infinite Discs":
      return infinite;
    case "Above Ground Level":
      return agl;
    case "Ozone Discs":
      return ozone;
    case "Streamline Discs":
      return streamline;
    case "Storm Disc Golf":
      return storm;
    case "Galaxy Disc Golf":
      return galaxy;
    case "Fourth Circle Discs":
      return fourthCircle;
    case "Guru Disc Golf":
      return guru;
    case "Mint Discs":
      return mint;
    case "Skyquest Discs":
      return skyquest;
    case "Kaufinator Discs":
      return kaufinator;
    case "ABC Discs":
      return abc;
    case "Arsenal Discs":
      return arsenal;
    case "Plastic Addicts":
      return plasticAddicts;
    case "Thought Space Athletics":
      return thoughtSpace;
    case "Wild Discs":
      return wild;
    case "XCOM Discs":
      return xcom;
    case "EV-7":
      return ev7;
    case "Cheengz/Hyperflite":
      return cheengz;
    case "Disc Golf UK Ltd.":
      return dguk;
    case "Dino Discs":
      return dino;
    case "Disctroyer OÜ":
      return disctroyer;
    case "Divergent Discs":
      return divergent;
    case "Elevation Disc Golf":
      return elevation;
    case "Launch Disc Golf":
      return launch;
    case "Lone Star Molding":
      return lsd;
    case "Quest Applied Technologies":
      return quest;
    case "Sune Sport":
      return sune;
    case "Crosslap Disc Golf Parks":
      return crosslap;
    case "Obsidian Discs Oy":
      return obsidian;
    case "Wing It Disc Golf":
      return wingit;
    case "Momentum Disc Golf AB":
      return momentum;
    case "Terminal Velocity Discs":
      return terminal;
    case "Birdie Disc Golf Supply Co.":
      return birdie;
    case "Clash Discs":
      return clash;
    case "Alfa Discs":
      return alfa;
    case "Finish Line":
      return finishline;
    case "Goliath Discs":
      return goliath;
    case "Loft Discs":
      return loft;
    case "UPlay":
      return uplay;
    case "Black Zombie Disc Golf":
      return blackZombie;
    case "Hooligan Discs":
      return hooligan;
    case "Premier Discs":
      return premier;
    case "Kestrel Outdoors":
      return kestrel;
    case "Northstar":
      return northstar;
    case "Doomsday Discs":
      return doomsday;
    case "Albatross Disc Golf":
      return albatross;
    case "Deity Discs":
      return deity;
    case "Disc King":
      return discKing;
    case "Discwing, Ltd.":
      return discwing;
    case "DMI Sports":
      return dmiSports;
    case "Essential Discs":
      return essential;
    case "Eurodisc":
      return eurodisc;
    case "Ferris State University":
      return ferrisState;
    case "LB Sport Loisir":
      return lbSport;
    case "Parked LLC":
      return parkedLLC;
    case "Paradigm Disc Golf":
      return paradigm;
    case "Pie Pan Discs":
      return piePan;
    case "Skyiron":
      return skyIron;
    case "Snap Discsports":
      return snap;
    case "Synergy Discs":
      return synergy;
    case "Tokyo Discs":
      return tokyoDiscs;
    case "Tornado Throw":
      return tornado;
    case "Wham-O, Inc.":
      return whamo;
    case "Trash Panda Disc Golf":
      return trashPanda;
    case "Fly High Discs":
      return flyHigh;
    case "GT Discs":
      return gtDiscs;
    case "Hero Disc":
      return heroDisc;
    case "Las Aves Disc Golf":
      return lasAves;
    case "Newb SimHouse Custom Discs":
      return newbSimhouse;
    case "Remix Disc Golf":
      return remix;
    case "Taito Frisbeegolf":
      return taito;
    default:
      return brand;
  }
};

export default Logo;
