import axios from "axios";

export default () => {
  const getUrl = window.location;
  const currentUrl = getUrl.protocol + "/" + getUrl.host + "/";

  let baseURL;

  if (currentUrl === "http:/localhost:3000/") {
    baseURL = "http://localhost:5000";
  } else {
    baseURL = "https://squalladiscgolf.com";
  }

  return axios.create({
    baseURL,
  });
};
