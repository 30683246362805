import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";

import Header from "./Components/Header/Header";
import Discs from "./Components/Discs/Discs";

import "./Styles.css";

const ShareBag = () => {
  const [bag, setBag] = useState({});
  const [filteredDiscs, setFilteredDiscs] = useState([]);
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [learnMoreData, setLearnMoreData] = useState(null);
  const [chartOpen, setChartOpen] = useState(false);
  const [gridOpen, setGridOpen] = useState(false);
  const [filter, setFilter] = useState("all");

  const { bagId } = useParams();
  useEffect(() => {
    axios()
      .get(`/api/collections/bag/${bagId}`)
      .then((res) => {
        const discs = res.data.bag.discs.map((disc) => {
          return {
            ...disc,
            data: res.data.discDatas.find((dd) => dd._id == disc.disc_id),
          };
        });

        const data = {
          title: res.data.bag.title,
          _id: res.data.bag._id,
          description: res.data.bag.description,
          discs,
        };
        setBag(data);
        setFilteredDiscs(discs);
      });
  }, [bagId]);

  const toggleLearnMore = () => {
    setLearnMoreOpen(!learnMoreOpen);
  };

  const handleSetLearnMoreData = (data) => {
    toggleLearnMore();
    setLearnMoreData(data);
  };

  const toggleChart = () => {
    setChartOpen(!chartOpen);
  };

  const toggleGrid = () => {
    setGridOpen(!gridOpen);
  };

  const handleSetFilter = (name) => {
    setFilter(name);

    if (name === "all") {
      setFilteredDiscs(bag.discs);
    } else if (name === "drivers") {
      setFilteredDiscs(
        bag.discs.filter((d) => d.data.type === "Distance Driver")
      );
    } else if (name === "fairways") {
      setFilteredDiscs(
        bag.discs.filter((d) => d.data.type === "Fairway Driver")
      );
    } else if (name === "midranges") {
      setFilteredDiscs(bag.discs.filter((d) => d.data.type === "Mid Range"));
    } else if (name === "putters") {
      setFilteredDiscs(bag.discs.filter((d) => d.data.type === "Putter"));
    }
  };
  return (
    <div className="shareBagWrapper">
      <Header
        title={bag.title}
        toggleGrid={toggleGrid}
        toggleChart={toggleChart}
      />
      <Discs
        discs={filteredDiscs}
        learnMoreOpen={learnMoreOpen}
        learnMoreData={learnMoreData}
        handleSetLearnMoreData={handleSetLearnMoreData}
        gridOpen={gridOpen}
        toggleGrid={toggleGrid}
        toggleLearnMore={toggleLearnMore}
        chartOpen={chartOpen}
        toggleChart={toggleChart}
      />
    </div>
  );
};

export default ShareBag;
