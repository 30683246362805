import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Landing from "./Landing";
import ShareBag from "./ShareBag/ShareBag";
import PrivacyPolicy from "./Privacy/PrivacyPolicy";
import Releases from "./Releases/Releases";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/bag/:bagId",
    element: <ShareBag />,
  },
  {
    path: "/releases",
    element: <Releases />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
