import React, { useState } from "react";

import Ratings from "../Ratings/Ratings";

import getLogo from "../Logo/getLogo";
import AmChart from "../../../utils/amCharts";

import "./Styles.css";

const LearnMore = ({ data, width }) => {
  const [readMore, setReadMore] = useState(false);

  const setDescription = () => {
    if (data.data.description) {
      if (readMore) {
        return data.data.description;
      } else {
        return data.data.description.slice(0, 200) + "...";
      }
    }
  };

  return (
    <div className="learnMoreWrapper">
      <div className="learnMoreLogoContainer">
        <img className="learnMoreLogo" src={getLogo(data.data.brand)} />
      </div>
      <div className="learnMoreHeadingContainer">
        <p className="learnMoreHeading1">{data.data.title}</p>
        <p className="learnMoreHeading2">{data.data.type}</p>
        <p className="learnMoreHeading3">{data.data.stability}</p>
      </div>
      <div className="learnMoreDescriptionContainer">
        <p className="learnMoreDescription">{setDescription()}</p>
        <div className="readMoreButtonContainer">
          <button
            className="readMoreButton"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>
      <div className="learnMoreRatingsContainer">
        <p className="learnMoreRatingsTitle">| Speed | Glide | Turn | Fade |</p>
        <div className="learnMoreRatingsItem">
          <p className="learnMoreRatingsItemTitle">Manufacture:</p>
          <div>
            <Ratings ratings={data.data.man_ratings} />
          </div>
        </div>
        <div className="learnMoreRatingsItem">
          <p className="learnMoreRatingsItemTitle">Infinite:</p>
          <div>
            <Ratings ratings={data.data.infinite_ratings} />
          </div>
        </div>
      </div>
      <div className="learnMoreRatingsContainer">
        <p className="learnMoreRatingsTitle">| Distance | HST | LSF | Net |</p>
        <div className="learnMoreRatingsItem">
          <p className="learnMoreRatingsItemTitle">Inbounds:</p>
          <div>
            <Ratings ratings={data.data.inbounds_ratings} inbounds />
          </div>
        </div>
      </div>
      <div className="learnMoreSpecsContainer">
        <p className="learnMoreSpecsTitle">Specifications</p>
        <div>
          {data.data.approved_date ? (
            <p className="learnMoreSpecsP">
              PDGA Approved:{" "}
              <span className="learnMoreSpecsT">{data.data.approved_date}</span>
            </p>
          ) : null}
          {data.data.certification_number ? (
            <p className="learnMoreSpecsP">
              Certification #:{" "}
              <span className="learnMoreSpecsT">
                {data.data.certification_number}
              </span>
            </p>
          ) : null}
          {data.data.diameter ? (
            <p className="learnMoreSpecsP">
              Diameter:{" "}
              <span className="learnMoreSpecsT">{data.data.diameter}cm</span>
            </p>
          ) : null}
          {data.data.height ? (
            <p className="learnMoreSpecsP">
              Height:{" "}
              <span className="learnMoreSpecsT">{data.data.height}cm</span>
            </p>
          ) : null}
          {data.data.rim_depth ? (
            <p className="learnMoreSpecsP">
              Rim Depth:{" "}
              <span className="learnMoreSpecsT">{data.data.rim_depth}cm</span>
            </p>
          ) : null}
          {data.data.rim_thickness ? (
            <p className="learnMoreSpecsP">
              Rim Thickness:{" "}
              <span className="learnMoreSpecsT">
                {data.data.rim_thickness}cm
              </span>
            </p>
          ) : null}
          {data.data.rim_diameter_ratio ? (
            <p className="learnMoreSpecsP">
              Rim Diameter Ratio:{" "}
              <span className="learnMoreSpecsT">
                {data.data.rim_diameter_ratio}
              </span>
            </p>
          ) : null}
          {data.data.rim_configuration ? (
            <p className="learnMoreSpecsP">
              Rim Configuration:{" "}
              <span className="learnMoreSpecsT">
                {data.data.rim_configuration}cm
              </span>
            </p>
          ) : null}
          {data.data.flexibility ? (
            <p className="learnMoreSpecsP">
              Flexibility:{" "}
              <span className="learnMoreSpecsT">{data.data.flexibility}kg</span>
            </p>
          ) : null}
          {data.data.bead ? (
            <p className="learnMoreSpecsP">
              Bead: <span className="learnMoreSpecsT">{data.data.bead}</span>
            </p>
          ) : null}
        </div>
      </div>
      {data.data.chartData && (
        <div className="amChartContainer">
          <AmChart chartData={[data.data]} />
        </div>
      )}
    </div>
  );
};

export default LearnMore;
