import React from "react";
import orderHistory from "../../../assets/profileSection/orderHistory.png";
import tournaments from "../../../assets/profileSection/tournaments.png";
import rating from "../../../assets/profileSection/profile.png";

import "./Styles.css";

const images = [
  {
    image: orderHistory,
    title: "Order History & Tracking",
  },
  {
    image: tournaments,
    title: "View Past Tournaments",
  },
  {
    image: rating,
    title: "Rating Tracking",
  },
];

const ProfileSection = () => {
  const mapImages = () => {
    return images.map((val, i) => (
      <div className="profileSectionImageContainer">
        <p className="profileSectionImageTitle">{val.title}</p>
        <img
          src={val.image}
          className="profileSectionImage"
          id={`profileSectionImage${i + 1}`}
        />
      </div>
    ));
  };

  return (
    <div className="profileSectionWrapper">
      <div className="profileSectionContainer">{mapImages()}</div>
    </div>
  );
};

export default ProfileSection;
