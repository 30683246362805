import React from "react";

const Release = ({ data }) => {
  return (
    <div className="releaseWrapper">
      <p className="releaseTitle">
        Release: <span className="releaseTitleVal">version {data.release}</span>
      </p>
      <p className="releaseTitle">
        Date: <span className="releaseTitleVal">{data.date}</span>
      </p>
      <div className="releaseItemsWrapper">
        {data.items.map((item) => (
          <div className="releaseItemContainer">
            <p className="releaseItemTitle">{item.title}</p>
            <div className="releaseItemItemsWrapper">
              {item.items.map((i) => (
                <div className="releaseItemItemsContainer">
                  {i.title ? (
                    <>
                      <p className="releaseItemItemTitle">{i.title}</p>
                      <p className="releaseItemItemDesc">- {i.description}</p>
                    </>
                  ) : (
                    <p className="releaseItemItemDesc">- {i.description}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Release;
