import React from "react";

import "./Styles.css";

const Header = ({ title, toggleGrid, toggleChart }) => {
  return (
    <div className="shareHeaderWrapper mdl-shadow--8dp">
      <div className="shareHeaderTitleContainer">
        <p className="shareHeaderTitle">{title}</p>
      </div>
      <div className="shareHeaderButtonsContainer">
        <div className="shareButtonContainer">
          <a className="shareHeaderHome" href="/">
            Home
          </a>
        </div>
        <div className="shareButtonContainer">
          <button className="shareHeaderButton" onClick={toggleChart}>
            <i className="material-icons">show_chart</i>
          </button>
        </div>
        <div className="shareButtonContainer">
          <button className="shareHeaderButton" onClick={toggleGrid}>
            <i className="material-icons">bubble_chart</i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
